var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sidebar-general',{ref:"sidebarCrearProfesional",attrs:{"title":"Crear profesional","aceptar":"Crear"},on:{"aceptar":_vm.crearProfesional}},[_c('ValidationObserver',{ref:"form"},[_c('b-container',{staticClass:"pt-1"},[_c('div',[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Nombre")]),_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Nombre"},model:{value:(_vm.profesional.nombre),callback:function ($$v) {_vm.$set(_vm.profesional, "nombre", $$v)},expression:"profesional.nombre"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('label',{attrs:{"for":"apellido"}},[_vm._v("Apellido")]),_c('ValidationProvider',{attrs:{"name":"apellido","rules":"required|max:25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Apellido"},model:{value:(_vm.profesional.apellido),callback:function ($$v) {_vm.$set(_vm.profesional, "apellido", $$v)},expression:"profesional.apellido"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('label',[_vm._v("Email")]),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.profesional.email),callback:function ($$v) {_vm.$set(_vm.profesional, "email", $$v)},expression:"profesional.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('label',[_vm._v("Teléfono")]),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|numeric|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Teléfono"},model:{value:(_vm.profesional.telefono),callback:function ($$v) {_vm.$set(_vm.profesional, "telefono", $$v)},expression:"profesional.telefono"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('label',{attrs:{"for":"identificacion"}},[_vm._v("Identificación")]),_c('ValidationProvider',{attrs:{"name":"identificación","rules":"required|numeric|min:5|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","placeholder":"Identificación","state":errors.length > 0 ? false : null},model:{value:(_vm.profesional.identificacion),callback:function ($$v) {_vm.$set(_vm.profesional, "identificacion", $$v)},expression:"profesional.identificacion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('label',{attrs:{"for":"tipo identificacion"}},[_vm._v("Tipo de identificación")]),_c('ValidationProvider',{attrs:{"name":"tipo identificación","rules":"required|between:1,3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"filterable":false,"placeholder":"Seleccionar tipo","options":_vm.tiposDoc,"label":"nombre","reduce":function (doc) { return doc.id; }},model:{value:(_vm.profesional.tipo_identificacion),callback:function ($$v) {_vm.$set(_vm.profesional, "tipo_identificacion", $$v)},expression:"profesional.tipo_identificacion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Contraseña")]),_c('ValidationProvider',{attrs:{"name":"contraseña","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Contraseña"},model:{value:(_vm.profesional.password),callback:function ($$v) {_vm.$set(_vm.profesional, "password", $$v)},expression:"profesional.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Confirmar contraseña")]),_c('ValidationProvider',{attrs:{"name":"confirmar contraseña","rules":"required|confirmed:contraseña"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Confirmar contraseña"},model:{value:(_vm.profesional.password_confirmation),callback:function ($$v) {_vm.$set(_vm.profesional, "password_confirmation", $$v)},expression:"profesional.password_confirmation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('label',{attrs:{"for":"nacimiento"}},[_vm._v("Fecha de nacimiento")]),_c('ValidationProvider',{attrs:{"name":"fecha nacimiento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"label-reset-button":"Borrar","placeholder":"Fecha","state":errors.length > 0 ? false : null,"reset-button":"","date-format-options":{
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            }},model:{value:(_vm.profesional.fecha_nacimiento),callback:function ($$v) {_vm.$set(_vm.profesional, "fecha_nacimiento", $$v)},expression:"profesional.fecha_nacimiento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mt-1"},[_c('label',{attrs:{"for":"genero"}},[_vm._v("Genero")]),_c('ValidationProvider',{attrs:{"name":"genero","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.generos,"reduce":function (genero) { return genero.id; },"label":"genero","placeholder":"Selecciona el genero"},model:{value:(_vm.profesional.genero),callback:function ($$v) {_vm.$set(_vm.profesional, "genero", $$v)},expression:"profesional.genero"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"mt-1 pb-1"},[_c('label',{attrs:{"for":"vincular"}},[_vm._v("Vincular a una entidad")]),_c('ValidationProvider',{attrs:{"name":"entidad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"vincular","options":_vm.entidadesUser,"placeholder":"Buscar entidad","reduce":function (entidad) { return entidad.id; },"label":"nombre","multiple":"","filterable":""},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" No se encontraron entidades ")]},proxy:true}],null,true),model:{value:(_vm.profesional.id_entidades),callback:function ($$v) {_vm.$set(_vm.profesional, "id_entidades", $$v)},expression:"profesional.id_entidades"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }